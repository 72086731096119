// SidebarContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const SidebarContext = createContext();

// Create a provider component
export function SidebarProvider({ children }) {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarExpanded(prev => !prev);
    };

    return (
        <SidebarContext.Provider value={{ isSidebarExpanded, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
}

// Custom hook to use the SidebarContext
export function useSidebar() {
    return useContext(SidebarContext);
}
