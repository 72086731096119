import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useSidebar } from "../SidebarContext"; // Adjust the path as needed
import ProfileDropdown from "../components/ProfileDropdown";
import { useAuth } from "../contexts/AuthContext"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import { validateUserTier } from "../utils/authHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSync, faFile, faMap, faDashboard, faBell } from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";

function Dashboard() {
    const { user, userDoc } = useAuth();
    const { isSidebarExpanded, toggleSidebar } = useSidebar();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint as needed
    const sidebarRef = React.useRef(null);
    const dropdownRef = React.useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && userDoc) {
            validateUserTier(user, userDoc, navigate);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Update the state based on window size
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Initialize on load

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [user, userDoc, navigate]);

    const handleProfileClick = (event) => {
        event.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSidebarItemClick = (event, isDisabled) => {
        if (isDisabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        event.stopPropagation();
    };

    return (
        <div className="dashboard">
            {isMobile ? (
                <div className="bottom-navbar">
                    <Link to="" className="bottom-nav-item">
                        <FontAwesomeIcon icon={faDashboard} />
                    </Link>
                    <Link to="atlas" className="bottom-nav-item">
                        <FontAwesomeIcon icon={faMap} />
                    </Link>
                    <Link to="alerts" className="bottom-nav-item">
                        <FontAwesomeIcon icon={faBell} />
                    </Link>
                </div>
            ) : (
                <div
                    className={`sidebar ${isSidebarExpanded ? 'expanded' : ''}`}
                    onClick={toggleSidebar}
                    ref={sidebarRef}
                >
                    <div className="sidebar-icon">
                        <img src="assets/AthenaLogo.png" alt="Project Athena Logo" />
                    </div>
                    <div className={`sidebar-content ${isSidebarExpanded ? 'expanded' : ''}`}>
                        <Link to="" className="sidebar-item" onClick={(e) => handleSidebarItemClick(e, true)}>
                            <FontAwesomeIcon icon={faDashboard} />
                            {isSidebarExpanded && <span>Dashboard (TBD)</span>}
                        </Link>
                        <Link to="atlas" className="sidebar-item" onClick={(e) => handleSidebarItemClick(e, true)}>
                            <FontAwesomeIcon icon={faMap} />
                            {isSidebarExpanded && <span>Athena Atlas (TBD)</span>}
                        </Link>
                        <Link to="alerts" className="sidebar-item" onClick={handleSidebarItemClick}>
                            <FontAwesomeIcon icon={faBell} />
                            {isSidebarExpanded && <span>Athena Alerts</span>}
                        </Link>
                    </div>
                    <div className="sidebar-platform">
                        <a href="https://t.me/+pG1XWlA_6gE0Yjdk" className="sidebar-item" onClick={handleSidebarItemClick} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faPaperPlane} />
                            {isSidebarExpanded && <span>Telegram</span>}
                        </a>
                        <Link to="updates" className="sidebar-item" onClick={(e) => handleSidebarItemClick(e, true)}>
                            <FontAwesomeIcon icon={faSync} />
                            {isSidebarExpanded && <span>Updates</span>}
                        </Link>
                        <a href="https://docs.athena.foo/" className="sidebar-item" onClick={handleSidebarItemClick} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFile} />
                            {isSidebarExpanded && <span>Docs</span>}
                        </a>
                        <div className="sidebar-item" onClick={handleSidebarItemClick}>
                            <p>v0.1</p>
                            {isSidebarExpanded && <span>Athena Hub</span>}
                        </div>
                    </div>
                </div>
            )}

            <div className="profile-container" onClick={handleProfileClick}>
                <ProfileDropdown
                    userEmail={user ? user.email : ''}
                    className={isSidebarExpanded ? 'expanded' : 'collapsed'}
                    ref={dropdownRef}
                />
            </div>

            <main>
                <Outlet />
            </main>
        </div>
    );
}

export default Dashboard;
