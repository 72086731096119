// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Import your Firebase auth and db instances
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userDoc, setUserDoc] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        });

        return () => unsubscribeAuth();
    }, []);

    useEffect(() => {
        if (user) {
            // Real-time listener for Firestore user document
            const userDocRef = doc(db, 'users', user.uid);
            const unsubscribeDoc = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    setUserDoc(doc.data());
                } else {
                    setUserDoc(null); // Handle case where document does not exist
                }
            }, (error) => {
                console.error('Error listening to Firestore document:', error);
            });

            // Cleanup listener on component unmount or user change
            return () => unsubscribeDoc();
        } else {
            setUserDoc(null); // Clear document if no user is logged in
        }
    }, [user]);

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <AuthContext.Provider value={{ user, userDoc, auth, db }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
