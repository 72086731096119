import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Pricing from "./components/Pricing";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import DashboardContent from "./components/DashboardContent";
import AthenaAtlasContent from "./components/AthenaAtlasContent";
import AthenaAlertsContent from "./components/AthenaAlertsContent";
// import NotFound from "./components/NotFound"; // Assuming you have a NotFound component

function Routing() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route 
                    path="/pricing" 
                    element={
                        <ProtectedRoute>
                            <Pricing />
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/*" // Use a wildcard to allow for nested routes
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } 
                >
                    <Route path="*" element={<DashboardContent />} />
                    <Route path="atlas" element={<AthenaAtlasContent />} />
                    <Route path="alerts" element={<AthenaAlertsContent />} />
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Route>
            </Routes>
        </Router>
    );
}

export default Routing;
