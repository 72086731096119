import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext"; // Adjust path as needed

function ProtectedRoute({ children }) {
    const { user, loading } = useAuth(); // Access user and loading state from AuthContext

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;
