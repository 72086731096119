import { auth } from "../firebase";

export const checkUserStatusAndRedirect = async (user, userDoc, navigate) => {
    if (user) {
        try {
            if (userDoc.tier !== 'free') {
                navigate("/");
            } else {
                navigate("/pricing");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    }
};

export const validateUserTier = async (user, userDoc, navigate) => {
    if (user) {
        try {
            if (userDoc.tier === 'free') {
                navigate("/pricing");
            } else if (userDoc.tier === 'alerts_beta') {
                navigate("/alerts");
            } else if (userDoc.tier === 'basic') {
                navigate("/alerts");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    }
};

export const logoutUser = async () => {
    try {
        await auth.signOut();
        console.log("User signed out");
    } catch (error) {
        console.error("Error signing out:", error);
    }
};