import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAIYvVFVElgnKivKgkcbO14hB-pxvAzWaw",
    authDomain: "project-athena-430604.firebaseapp.com",
    databaseURL: "https://project-athena-430604-default-rtdb.firebaseio.com",
    projectId: "project-athena-430604",
    storageBucket: "project-athena-430604.appspot.com",
    messagingSenderId: "298997364111",
    appId: "1:298997364111:web:52cd38de96de0c7e05c7fa",
    measurementId: "G-G3N54BVM8N"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);