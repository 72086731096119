import React, { useState, useEffect, useCallback } from "react";
import TelegramLoginButton from 'react-telegram-login';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce';
import "./AthenaAlertsContent.css";

const handleTelegramResponse = async (response, user, db, setUserInfo) => {
    console.log(response);

    if (response && response.id) {
        setUserInfo({
            username: response.username,
            id: response.id,
        });

        try {
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                await setDoc(userDocRef, {
                    tg_username: response.username,
                    tg_id: response.id,
                }, { merge: true });
                console.log('User data successfully updated in Firestore');
            } else {
                console.error('User document does not exist');
            }
        } catch (error) {
            console.error('Error updating user data in Firestore:', error);
        }
    } else {
        console.error("Authentication failed");
    }
};

function AthenaAlertsContent() {
    const { user, userDoc, db } = useAuth();
    const [loading, setLoading] = useState(true);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [selectedBlockchains, setSelectedBlockchains] = useState([]);
    const [tier, setTier] = useState(''); // state to store user's tier
    const [blockchainOptions, setBlockchainOptions] = useState([]);
    const [contractVerifiedOptions, setContractVerifiedOptions] = useState([]);
    const [riskLevelOptions, setRiskLevelOptions] = useState([]);
    const [classificationOptions, setClassificationOptions] = useState([]);
    const [alertTypeOptions, setAlertTypeOptions] = useState([]);
    const [hasSocialOptions, setHasSocialOptions] = useState([]);
    const [buyTax, setBuyTax] = useState('');
    const [sellTax, setSellTax] = useState('');
    const [selectedContractVerified, setSelectedContractVerified] = useState('');
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [selectedClassification, setSelectedClassification] = useState('');
    const [selectedAlertType, setSelectedAlertType] = useState('');
    const [selectedHasSocial, setselectedHasSocial] = useState('');
    const [selectedDisplay, setSelectedDisplay] = useState('standard');

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                console.log(user)
                console.log(db)
                console.log(userDoc)
                // Fetch blockchain options
                const blockchainOptionsDocRef = doc(db, 'config', 'blockchainOptions');
                const blockchainOptionsDoc = await getDoc(blockchainOptionsDocRef);
                if (blockchainOptionsDoc.exists()) {
                    setBlockchainOptions(blockchainOptionsDoc.data().blockchainOptions || []);

                } else {
                    console.error('Blockchain options document does not exist');
                }

                // Fetch contract verification options
                const contractVerifiedDocRef = doc(db, 'config', 'contractVerifiedOptions');
                const contractVerifiedDoc = await getDoc(contractVerifiedDocRef);
                if (contractVerifiedDoc.exists()) {
                    setContractVerifiedOptions(contractVerifiedDoc.data().contractVerifiedOptions || []);
                } else {
                    console.error('Contract verification options document does not exist');
                }

                // Fetch risk level options
                const riskLevelDocRef = doc(db, 'config', 'riskLevelOptions');
                const riskLevelDoc = await getDoc(riskLevelDocRef);
                if (riskLevelDoc.exists()) {
                    setRiskLevelOptions(riskLevelDoc.data().riskLevels || []);
                } else {
                    console.error('Risk level options document does not exist');
                }

                // Fetch classification options
                const classificationDocRef = doc(db, 'config', 'classificationOptions');
                const classificationDoc = await getDoc(classificationDocRef);
                if (classificationDoc.exists()) {
                    setClassificationOptions(classificationDoc.data().classificationOptions || []);
                } else {
                    console.error('Classification options document does not exist');
                }

                // Fetch Alert Type options
                const alertTypeDocRef = doc(db, 'config', 'alertTypeOptions');
                const alertTypeDoc = await getDoc(alertTypeDocRef);
                if (alertTypeDoc.exists()) {
                    setAlertTypeOptions(alertTypeDoc.data().alertTypeOptions || []);
                } else {
                    console.error('Alert Type options document does not exist');
                }

                // Fetch Has Social options
                const hasSocialDocRef = doc(db, 'config', 'hasSocialOptions');
                const hasSocialDoc = await getDoc(hasSocialDocRef);
                if (hasSocialDoc.exists()) {
                    setHasSocialOptions(hasSocialDoc.data().hasSocialOptions || []);
                } else {
                    console.error('Has Socials options document does not exist');
                }
                setLoading(false);

            } catch (error) {
                console.error('Error fetching options:', error);
                setLoading(false);
            }
        };

        fetchOptions();
    }, [db]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                // Fetch options from Firestore...
                // (Keep your existing fetchOptions logic here)

                setLoading(false);
            } catch (error) {
                console.error('Error fetching options:', error);
                setLoading(false);
            }
        };

        fetchOptions();
    }, [db]);

    useEffect(() => {
        if (userDoc) {
            const { tg_username, tg_id, tier, filters } = userDoc;
            if (tg_username && tg_id) {
                setUserInfo({
                    username: tg_username,
                    id: tg_id,
                });
            }
            setTier(tier || '');

            if (filters) {
                setSelectedBlockchains(filters.blockchains || []);
                setSelectedClassification(filters.classification || '');
                setSelectedAlertType(filters.alertType || '');
                setBuyTax(filters.buyTax !== undefined ? filters.buyTax.toString() : '');
                setSellTax(filters.sellTax !== undefined ? filters.sellTax.toString() : '');
                setSelectedContractVerified(filters.contractVerified || '');
                setSelectedRiskLevel(filters.riskLevel || '');
                setSelectedDisplay(filters.displayPreference || 'standard');
                setselectedHasSocial(filters.hasSocial || '')
            }
        }
        setLoading(false);
    }, [userDoc]);

    const toggleCategory = (category) => {
        setExpandedCategory(prevCategory =>
            prevCategory === category ? null : category
        );
    };

    const handleBlockchainChange = async (e) => {
        let newSelection = e.value;

        if (selectedBlockchains.length === 1 && newSelection.length === 0) {
            return;
        }

        if (tier === 'basic') {
            // Allow only one selection for 'basic' tier
            newSelection = newSelection.slice(-1);
        }

        setSelectedBlockchains(newSelection);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        blockchains: newSelection,
                    }
                }, { merge: true });
                console.log('User filters successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user filters in Firestore:', error);
            }
        }
    };

    const debouncedHandleTaxChange = useCallback(debounce(async (type, value) => {
        let numericValue;

        if (value === '') {
            numericValue = 0;
        } else { numericValue = parseFloat(value); }

        if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
            return;
        }

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        [type]: numericValue,
                    }
                }, { merge: true });
                console.log(`User ${type} successfully updated in Firestore`);
            } catch (error) {
                console.error(`Error updating user ${type} in Firestore:`, error);
            }
        }
    }, 500), [user, db, userDoc]); // 500ms debounce time

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userDoc) {
        return <div>Error: Unable to load user data. Please try again later.</div>;
    }

    const handleTaxChange = (type, value) => {
        if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
            if (type === 'buyTax') {
                setBuyTax(value);
            } else if (type === 'sellTax') {
                setSellTax(value);
            }
            debouncedHandleTaxChange(type, value); // Use the debounced function
        }
    };

    const handleContractVerifiedChange = async (e) => {
        const newValue = e.target.value;
        setSelectedContractVerified(newValue);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        contractVerified: newValue,
                    }
                }, { merge: true });
                console.log('User contract verification status successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user contract verification status in Firestore:', error);
            }
        }
    };

    const handleRiskLevelChange = async (e) => {
        const newValue = e.target.value;
        setSelectedRiskLevel(newValue);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        riskLevel: newValue,
                    }
                }, { merge: true });
                console.log('User risk level successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user risk level in Firestore:', error);
            }
        }
    };

    const handleClassificationChange = async (e) => {
        const newValue = e.target.value;
        setSelectedClassification(newValue);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        classification: newValue,
                    }
                }, { merge: true });
                console.log('Classification successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user classification in Firestore:', error);
            }
        }
    };

    const handleAlertTypeChange = async (e) => {
        const newValue = e.target.value;
        setSelectedAlertType(newValue);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        alertType: newValue,
                    }
                }, { merge: true });
                console.log('Alert Type successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user alert type in Firestore:', error);
            }
        }
    };

    const handleHasSocialChange = async (e) => {
        const newValue = e.target.value;
        setselectedHasSocial(newValue);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        hasSocial: newValue,
                    }
                }, { merge: true });
                console.log('Has Social successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user has social in Firestore:', error);
            }
        }
    };

    const handleDisplayChange = async (display) => {
        setSelectedDisplay(display);

        if (user && db) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                await setDoc(userDocRef, {
                    filters: {
                        ...userDoc.filters,
                        displayPreference: display,
                    }
                }, { merge: true });
                console.log('User display preference successfully updated in Firestore');
            } catch (error) {
                console.error('Error updating user display preference in Firestore:', error);
            }
        }
    }

    const categories = [
        {
            title: "Alert Channels",
            type: "custom",
            content: (
                <div className="alert-channels-grid">
                    <div className="alert-channel-item">
                        <span>Telegram</span>
                        {userInfo ? (
                            <div>
                                <p>Linked to: @{userInfo.username}</p>
                            </div>
                        ) : (
                            <div className="telegram-button-wrapper">
                                <TelegramLoginButton
                                    dataOnauth={(response) => handleTelegramResponse(response, user, db, setUserInfo)}
                                    botName="project_athena_bot"
                                    buttonSize="medium"
                                    requestAccess="write"
                                    usePic={false}
                                    cornerRadius={4}
                                />
                            </div>
                        )}
                    </div>

                    <div className="alert-channel-item">
                        <span>Discord</span>
                        <button className="discord-button" disabled>Coming Soon</button>
                    </div>
                    <div className="alert-channel-item">
                        <span>Email</span>
                        <button className="email-button" disabled>Coming Soon</button>
                    </div>
                </div>
            ),
        },
        {
            title: "Filters",
            type: "custom",
            content: (
                <div className="filters-grid">
                    <div className="filter-item">
                        <span>Token Security</span>
                        <div className="filter-dropdowns">
                            <div className="filter-dropdown">
                                <label htmlFor="contract-verified">
                                    Only Contract Verified
                                    <span className="info-icon">
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        <span className="tooltip">'No' implies max risk level</span>
                                    </span>
                                </label>
                                <Dropdown
                                    id="contract-verified"
                                    value={selectedContractVerified}
                                    options={contractVerifiedOptions}
                                    onChange={handleContractVerifiedChange}
                                    placeholder="Select Contract Verification Status"
                                />
                            </div>
                            <div className="filter-dropdown">
                                <label htmlFor="risk-level">Max Risk Level</label>
                                <Dropdown
                                    id="risk-level"
                                    value={selectedRiskLevel}
                                    options={riskLevelOptions}
                                    onChange={handleRiskLevelChange}
                                    placeholder="Select Risk Level"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-item">
                        <span>Tokenomics</span>
                        <div className="filter-dropdowns">
                            <div className="filter-input">
                                <label htmlFor="buy-tax">Max Buy Tax (%)</label>
                                <input
                                    id="buy-tax"
                                    type="number"
                                    value={buyTax}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleTaxChange('buyTax', value);
                                    }}
                                    placeholder={buyTax || "0"}
                                    min="0"
                                    max="100"
                                />
                            </div>
                            <div className="filter-input">
                                <label htmlFor="sell-tax">Max Sell Tax (%)</label>
                                <input
                                    id="sell-tax"
                                    type="number"
                                    value={sellTax}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleTaxChange('sellTax', value);
                                    }}
                                    placeholder={sellTax || "0"}
                                    min="0"
                                    max="100"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-item">
                        <span>Other</span>
                        <div className="filter-dropdowns">
                            <div className="filter-dropdown">
                                <label htmlFor="blockchain-select">Blockchain</label>
                                <MultiSelect
                                    id="blockchain-select"
                                    value={selectedBlockchains}
                                    options={blockchainOptions}
                                    onChange={handleBlockchainChange}
                                    placeholder="Select Blockchain(s)"
                                />
                            </div>
                            <div className="filter-dropdown">
                                <label htmlFor="classification-select">Classification</label>
                                <Dropdown
                                    id="classification-select"
                                    className="wide-dropdown"
                                    value={selectedClassification}
                                    options={classificationOptions}
                                    onChange={handleClassificationChange}
                                    placeholder="Select Token Class"
                                />
                            </div>
                            <div className="filter-dropdown">
                                <label htmlFor="alert-type-select">Alert Type</label>
                                <Dropdown
                                    id="alert-type-select"
                                    className="wide-dropdown"
                                    value={selectedAlertType}
                                    options={alertTypeOptions}
                                    onChange={handleAlertTypeChange}
                                    placeholder="Select Alert Type"
                                />
                            </div>
                            <div className="filter-dropdown">
                                <label htmlFor="socials-select">Only Has Socials</label>
                                <Dropdown
                                    id="socials-select"
                                    className="wide-dropdown"
                                    value={selectedHasSocial}
                                    options={hasSocialOptions}
                                    onChange={handleHasSocialChange}
                                    placeholder="Select Has Social Preference"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "Display Preferences",
            type: "text",
            content: (
                <div className="display-grid">
                    <div 
                        className={`display-item ${selectedDisplay === 'standard' ? 'selected' : ''}`}
                        onClick={() => handleDisplayChange('standard')}
                    >
                        <span>Standard</span>
                        <p>The standard alert display</p>
                        <div className="display-item-image-container">
                        <img src="assets/StandardDisplay.png" alt="Standard Display" />
                        </div>
                    </div>
                    <div 
                        className={`display-item ${selectedDisplay === 'detailed' ? 'selected' : ''}`}
                        onClick={() => handleDisplayChange('detailed')}
                    >
                        <span>Detailed Audit</span>
                        <p>Comprehensive audit results</p>
                        <div className="display-item-image-container">
                        <img src="assets/DetailedDisplay.png" alt="Detailed Audit Display" />
                        </div>
                    </div>
                    <div 
                        className={`display-item ${selectedDisplay === 'emoji' ? 'selected' : ''}`}
                        onClick={() => handleDisplayChange('emoji')}
                    >
                        <span>Emoji Style</span>
                        <p>Simple, emoji-rich alerts</p>
                        <div className="display-item-image-container">
                        <img src="assets/EmojiDisplay.png" alt="Emoji Style Display" />
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="alerts-box">
            <div className="alerts-header">
                <h1>Athena Alerts - Settings</h1>
            </div>

            {categories.map(({ title, content }, index) => (
                <div
                    key={index}
                    className={`settings-category ${expandedCategory === title ? 'expanded' : ''}`}
                >
                    <div className="category-header" onClick={() => toggleCategory(title)}>
                        <h2>{title}</h2>
                        <span className="toggle-icon">
                            {expandedCategory === title ? '-' : '+'}
                        </span>
                    </div>
                    {expandedCategory === title && (
                        <div className="category-content">
                            {content}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default AthenaAlertsContent;
