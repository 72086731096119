import React, { useEffect, useState } from 'react';
import { useAuth } from "../contexts/AuthContext"; // Import useAuth hook from your AuthContext
import { useNavigate } from "react-router-dom";
import { checkUserStatusAndRedirect } from "../utils/authHelpers";
import { Check } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import './Pricing.css';

function Pricing() {
    const { user, userDoc, db } = useAuth(); // Access user, auth, and db from AuthContext
    const navigate = useNavigate();
    const [selectedTier, setSelectedTier] = useState(0); // Default to first tier for now
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (user && userDoc) {
            checkUserStatusAndRedirect(user, userDoc, navigate); // Use navigate directly for redirection
        }
    }, [user, userDoc, navigate]);

    const tiers = [
        {
            title: "Starter",
            subtitle: "For new users looking to research promising tokens",
            price: "Free",
            period: "Per month",
            features: [
                "Full access to Athena Alerts",
                "Limited access to Athena Hub",
                "Single chain alerts"
            ],
            tryFree: true
        },
        {
            title: "Standard",
            subtitle: "Use the core features of the Athena platform to maximize your investments",
            price: "$24.95 USD",
            period: "Per month",
            features: [
                "Full access to Athena Alerts",
                "Full access to Athena Hub",
                "Full access to Athena Atlas",
                "Omni chain alerts",
                "Limited access to Odysseus"
            ],
        },
        {
            title: "Pro",
            subtitle: "Experimental and AI-powered features, doing all the analytics for you",
            price: "$499.95 USD",
            period: "Per month",
            features: [
                "Everything included in Standard",
                "Early access to new features",
                "Full access to Odysseus",
                "Full access to Athena AI",
                "Eligible for $ATHENA airdrops"
            ],
        },
    ];

    const handleTierClick = (index) => {
        setSelectedTier(index);
    };

    const getButtonText = () => {
        const tier = tiers[selectedTier];
        if (selectedTier === 0) return isUpdating ? "Updating..." : "Try now for free";
        return `${tier.title} coming soon`;
    };

    const isButtonDisabled = selectedTier !== 0 || isUpdating || !user;

    const handleSelectTier = async () => {
        if (selectedTier === 0 && user) {
            setIsUpdating(true);
            try {
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, {
                    tier: 'alerts_beta'
                });
                alert('Your tier has been updated to Alerts Beta!');
                // Optionally, you can redirect the user or update the UI here
            } catch (error) {
                console.error("Error updating user tier:", error);
                alert('There was an error updating your tier. Please try again.');
            } finally {
                setIsUpdating(false);
            }
        }
    };

    return (
        <div className="pricing-container">
            <h1>Pricing</h1>
            <p className="subtitle">Maximize your investments with Project Athena. Upgrade at any time.</p>
            <div className="tiers-container">
                {tiers.map((tier, index) => (
                    <div 
                        className={`tier ${index === selectedTier ? 'highlight' : ''}`} 
                        key={index}
                        onClick={() => handleTierClick(index)}
                    >
                        {tier.tryFree && <div className="try-free-badge">Open beta access</div>}
                        <div className="tier-content">
                            <h2>{tier.title}</h2>
                            <p className="tier-subtitle" title={tier.subtitle}>{tier.subtitle}</p>
                            <ul>
                                {tier.features.map((feature, i) => (
                                    <li key={i}><Check size={16} /> <span>{feature}</span></li>
                                ))}
                            </ul>
                            <div className="price">
                                <h3>{tier.price}</h3>
                                <span>{tier.period}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button 
                className={`select-tier-button ${isButtonDisabled ? 'disabled' : ''}`}
                disabled={isButtonDisabled}
                onClick={handleSelectTier}
            >
                {getButtonText()}
            </button>
        </div>
    );
}

export default Pricing;
