import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../utils/authHelpers";
import "./ProfileDropdown.css";

function ProfileDropdown({ userEmail }) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null); // Reference to the dropdown

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownVisible]);

    const handleSettings = () => {
        // Handle settings click
        alert("Settings - Coming Soon!");
    };

    const handleSubscription = () => {
        // Handle subscription click
        alert("Subscription - Coming Soon!");
    };

    const handleLogout = async () => {
        logoutUser();
    };

    return (
        <div className="profile-section" ref={dropdownRef}>
            <button onClick={toggleDropdown} className="profile-button">
                <span className="user-info">{userEmail}</span>
                <FontAwesomeIcon icon={faUserCircle} />
            </button>
            {dropdownVisible && (
                <div className="dropdown-menu">
                    <button onClick={handleSettings}>Settings</button>
                    <button onClick={handleSubscription}>Subscription</button>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            )}
        </div>
    );
}

export default ProfileDropdown;
