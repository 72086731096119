// src/components/Login.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext"; // Import useAuth hook from your AuthContext
import { setDoc, doc } from "firebase/firestore";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { checkUserStatusAndRedirect } from "../utils/authHelpers";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import './Login.css';

// Custom function to map Firebase error codes to user-friendly messages
const getErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return "This email is already in use. Please use a different email or log in.";
        case 'auth/invalid-email':
            return "The email address is not valid. Please enter a valid email.";
        case 'auth/user-not-found':
            return "No account found with this email. Please register or try a different email.";
        case 'auth/wrong-password':
            return "Incorrect password. Please try again.";
        case 'auth/weak-password':
            return "Password should be at least 6 characters.";
        default:
            return "An error occurred. Please try again.";
    }
};

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [error, setError] = useState(""); // Error state to store error messages
    const { user, userDoc, auth, db } = useAuth(); // Access user, auth, and db from AuthContext
    const navigate = useNavigate();

    // Effect to handle redirection if user is authenticated
    useEffect(() => {
        if (user && userDoc) {
            checkUserStatusAndRedirect(user, userDoc, navigate); // Use navigate directly for redirection
        }
    }, [user, userDoc, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            let userCredential;
            if (isRegister) {
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
                // Add user to Firestore
                await setDoc(doc(db, "users", userCredential.user.uid), {
                    email: userCredential.user.email,
                    tier: 'free',
                });
            } else {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            }
            // Redirect user after successful login/register
            navigate('/'); // Adjust based on your routing setup
        } catch (error) {
            const errorMessage = getErrorMessage(error.code); // Get a user-friendly error message
            setError(errorMessage); // Set the custom error message
            console.error("Error signing in/registering: ", error);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-box">
                    <h2>Welcome to AthenaHub</h2>
                    <p>Please login to continue</p>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                        />
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className="password-icon"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <button type="submit">{isRegister ? "Register" : "Login"}</button>
                    </form>
                    {error && <div className="error-message">{error}</div>} {/* Display the error message */}
                    <div className="toggle-text-container">
                        {isRegister ? (
                            <p>Already have an account? <span className="toggle-text" onClick={() => setIsRegister(false)}><b>Login</b></span></p>
                        ) : (
                            <p>Don't have an account? <span className="toggle-text" onClick={() => setIsRegister(true)}><b>Register</b></span></p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
