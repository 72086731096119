// AthenaAtlasContent.js
import React, { useEffect } from "react";
import { useSidebar } from "../SidebarContext"; // Adjust the path as needed
import { useAuth } from "../contexts/AuthContext"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import { validateUserTier } from "../utils/authHelpers";

function AthenaAtlasContent() {
    const { user, userDoc } = useAuth(); // Access user from AuthContext
    const { isSidebarExpanded, toggleSidebar } = useSidebar();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const sidebarRef = React.useRef(null);
    const dropdownRef = React.useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && userDoc) {
            validateUserTier(user, userDoc, navigate); // Use navigate directly for redirection
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [user, userDoc, navigate]);


    return <div>Athena Atlas Content</div>;
}

export default AthenaAtlasContent;
