import React from "react";
import { useSidebar } from "../SidebarContext"; // Adjust the path as needed
import "./DashboardContent.css"; // Ensure this path is correct

function DashboardContent() {
    const { isSidebarExpanded } = useSidebar();

    return (
        <div
            className={`content-box ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}
        >
            {/* Placeholder Animation */}
            <div className="placeholder-animation">
                <div className="loading-symbol"></div>
            </div>
        </div>
    );
}

export default DashboardContent;
